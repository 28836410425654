/* Animaciones */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

html {
  scroll-behavior: smooth;
}

.home-container {
  font-family: Arial, sans-serif;
}

/* Estilos del Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  width: 97%;
  z-index: 1000;
  margin: 18px 26px;
}

/* New styles for green header */
.green-header {
  background-color: #d7e3df !important;
}

.green-header .nav-menu ul li a {
  color: #105a41 !important;
}

.green-header .nav-menu ul li a:hover {
  color: #BFBDA4 !important;
}

.logo {
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo-img {
  width: 185px;
  height: auto;
  background-color: #ffffff;
  border-top-right-radius: 91px;
}

.nav-menu ul {
  list-style: none;
  display: flex;
  gap: 20px;
  margin-right: 22px;
}

.nav-menu ul li a {
  text-decoration: none;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
}

.nav-menu ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  background-color: #BFBDA4;
  left: 0;
  bottom: -2px;
  transition: width 0.3s ease;
}

.nav-menu ul li a:hover::after {
  width: 100%;
}

/* Estilos del Banner */
.banner {
  padding: 50px 20px;
  background-color: white;
  display: flex;
  justify-content: center;
}

.banner-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  flex-direction: row;
}
.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

.banner-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.banner-logo-img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.formulario {
  flex: 1;
  padding-left: 20px;
}

.banner-form-section {
  flex: 1;
  padding-left: 20px;
}

.banner-form-section h3 {
  color: #07593B;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.banner-form {
  padding-right: 57px !important;
  background-color: #105a412b;
  padding: 30px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 500px;
}

.banner-form h3 {
  color: #07593B;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.5rem;
}


.form-group {
  margin-bottom: 15px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fff;
  color: #333;
  appearance: none;
}

.form-group input[type="checkbox"] {
  width: auto;
  padding: 0;
  margin-right: 10px; /* Espacio entre el checkbox y el texto */
  border: none;
  border-radius: 0;
  background: none;
  appearance: checkbox;
}

.form-group label {
  margin: 0;
  font-size: 16px;
}

.form-group select {
  position: relative;
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10"><path fill="none" stroke="%23333" stroke-width="2" d="M1 1l6 6 6-6"/></svg>') no-repeat right 12px center;
  background-color: white;
  background-size: 12px;
  padding-right: 40px;
}

.form-group textarea {
  resize: vertical;
}

.form-group select:hover {
  border-color: #888;
}

.form-group select:focus {
  outline: none;
  border-color: #007bff;
}


.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.form-button {
  background-color: #07593B;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-align: center;
}

.form-button:hover {
  background-color: #063f2d;
}

/* Footer style */
.green-header {
  color: #004d40;
  padding: 0px;
  text-align: center;
  position: sticky;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content p {
  font-size: 16px;
  margin: 0;
}

.footer-content a {
  color: #004d40;
  text-decoration: underline;
  font-weight: bold;
}

.footer-content a:hover {
  text-decoration: underline;
}

.footer-logo {
  max-width: 100px; /* Tamaño ajustable de la imagen */
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .banner-container {
    flex-direction: column; /* Para pantallas pequeñas, el logo y el formulario se apilan verticalmente */
  }

  .banner-logo {
    margin-bottom: 20px;
  }

  .formulario {
    padding-left: 0;
  }

  .hamburger-menu {
    display: block;
  }
}

/* Ajusta el container del formulario si es necesario */
.form-container {
  width: 100%;
}



/* Estilos para los checkboxes */


.link {
  color: #07593B;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover {
  color: #063f2d;
}

/* Bootstrap Modal Styles */
.modal-header {
  background-color: #07593B;
  color: white;
  border-bottom: none;
}

.modal-title {
  color: white !important;
}

.modal-header .btn-close {
  color: white;
  opacity: 1;
}

.modal-body {
  padding: 20px;
}

.modal-footer {
  border-top: none;
}

.modal-footer .btn-secondary {
  background-color: #07593B;
  border-color: #07593B;
}

.modal-footer .btn-secondary:hover {
  background-color: #063f2d;
  border-color: #063f2d;
}

/* Ajuste para el botón de envío */
.form-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Estilos de la sección de Servicios */
.services-section {
  margin: 50px 26px;
  padding: 20px;
  background-color: #f7f7f7;
}

.services-section h2 {
  text-align: center;
  color: #07593B;
  margin-bottom: 30px;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.service {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  min-width: 250px;
  max-width: 300px;
}

.service-image {
  width: 100%;
  max-width: 200px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.service-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 10px;
}

.service-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(7, 89, 59, 0.4);
}

.service-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  opacity: 0;
}

.service-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(7, 89, 59, 0.4);
}

.service h3 {
  color: #07593B;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.service p {
  font-size: 0.9rem;
  color: #333;
}

/* Estilos de la sección de Proyectos */
.projects-section {
  margin: 50px 26px;
  padding: 20px;
  background-color: #f7f7f7;
}

.projects-section h2 {
  text-align: center;
  color: #07593B;
  margin-bottom: 30px;
}

.projects {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;
  min-width: 250px;
  max-width: 400px;
}

.project-image {
  width: 100%;
  max-width: 200px;
  height: 150px;
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.project-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  padding: 10px;
}

.project-image:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(7, 89, 59, 0.4);
}

.project-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 200%;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
  opacity: 0;
}

.project-content:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(7, 89, 59, 0.4);
}

.project h3 {
  color: #07593B;
  margin-bottom: 10px;
}

/* Estilos de la sección de Contacto */
.contact-section {
  margin: 50px 26px;
  padding: 20px;
}

.contact-section h2 {
  text-align: center;
  color: #07593B;
  margin-bottom: 20px;
}

.contact-section p {
  text-align: center;
  margin-bottom: 30px;
}

.contact-info {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 30px;
}

.city {
  background-color: #f7f7f7;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 70%;
  min-width: 300px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.city:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(7, 89, 59, 0.4);
}

.city h3 {
  color: #07593B;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.city p {
  margin-bottom: 5px;
  color: #333;
}

/* Estilos para los mapas */
.map-container {
  margin-top: 15px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.map-container iframe {
  border: none;
  transition: opacity 0.3s ease;
}

/* Clases para animaciones */
.animate-left {
  animation: slideInFromLeft 1s ease-out forwards;
}

.animate-right {
  animation: slideInFromRight 1s ease-out forwards;
}

/* Media Queries para responsividad */
@media (max-width: 768px) {
  .header {
    padding: 10px;
    margin: 10px;
    width: 95%;
  }
  
  .nav-menu ul {
    gap: 10px;
    font-size: 0.9rem;
  }

  .banner-content {
    flex-direction: column;
  }
  
  .banner-logo,
  .banner-form-container {
    width: 100%;
  }
  
  .banner-logo {
    margin-bottom: 30px;
  }

  .services {
    flex-direction: column;
    align-items: center;
  }
  
  .service {
    width: 100%;
    max-width: 100%;
  }
  
  .project {
    width: 100%;
    max-width: 100%;
  }
  
  .project-content {
    width: 100%;
  }
  
  .city {
    width: 100%;
    min-width: 250px;
  }
  
  .map-container {
    height: 200px;
  }
}
.logo {
  width: 185px;
  height: auto;
}

/* Estilos para los modales */
.modal-content {
  background-color: transparent;
}

.modal-header {
  background-color: rgba(7, 89, 59, 0.9);
  color: white;
  border-bottom: none;
  position: relative;
  z-index: 1;
}

.modal-body {
  padding: 20px;
  position: relative;
  color: #000000;
}

.modal-footer {
  background-color: rgba(255, 255, 255, 0.9);
  border-top: none;
  position: relative;
  z-index: 1;
}

/* Estilos específicos para el contenido de los modales */
.modal-body .principal {
  position: relative;
  z-index: 2;
}

.modal-body .container h1 {
  color: #07593B;
  margin-bottom: 20px;
  font-size: 24px;
}

.modal-body .container ul {
  background-color: transparent;
  padding: 20px;
  border-radius: 5px;
  list-style-type: none;
}

.modal-body .container ul li {
  margin-bottom: 10px;
  padding-left: 20px;
  text-align: justify;
  position: relative;
}

.modal-body .container p {
  text-align: justify;
}

.modal-body .container ul li:before {
  content: "•";
  color: #07593B;
  font-weight: bold;
  position: absolute;
  left: 0;
}

.modal-body .logo {
  display: block;
  margin: 0 auto 20px;
  max-width: 200px;
  height: auto;
}

/* Estilos responsivos para los modales */
@media (max-width: 768px) {
  .modal-dialog {
    margin: 10px;
  }
  
  .modal-body .container h1 {
    font-size: 20px;
  }
  
  .modal-body .logo {
    max-width: 150px;
  }


  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background-color: #d7e3df;
    padding-top: 60px;
    transition: 0.3s;
    z-index: 1000;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    align-items: center;
  }

  .nav-menu ul li {
    margin: 15px 0;
  }

  /* Animación del menú hamburguesa */
  .header {
    position: relative;
  }


  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background-color: #d7e3df;
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    padding-top: 70px;
    height: 100%;
  }

  .nav-menu ul li {
    margin: 15px 0;
  }

  .nav-menu ul li a {
    font-size: 18px;
  }


 .banner-container {
    flex-direction: column;
  }
  
  .banner-logo,
  .banner-form-section {
    width: 100%;
    padding: 0;
  }
  
  .banner-logo {
    margin-bottom: 30px;
  }
}

/* Ajusta el container del formulario si es necesario */
.form-container {
  width: 100%;
}

@media (max-width: 768px) {
  .header {
    position: relative;
  }

  .nav-menu {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background-color: #d7e3df;
    transition: right 0.3s ease-in-out;
    z-index: 1000;
  }

  .nav-menu.active {
    right: 0;
  }

  .nav-menu ul {
    flex-direction: column;
    padding-top: 70px;
    height: 100%;
  }

  .nav-menu ul li {
    margin: 15px 0;
  }

  .nav-menu ul li a {
    font-size: 18px;
  }

  /* Animación del menú hamburguesa */
  .hamburger-menu.active span:first-child {
    transform: rotate(45deg) translate(4px, -1px);
  }

  .hamburger-menu.active span:nth-child(2) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  .hamburger-menu.active span:last-child {
    transform: rotate(-45deg) translate(3px, -4px);
  }

}

.hamburger-menu {
  display: none;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block;
  }
}

.hamburger-menu {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
}

.hamburger-menu span {
  display: block;
  width: 25px;
  height: 3px;
  margin-bottom: 5px;
  position: relative;
  background: #105a41;
  border-radius: 3px;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

.hamburger-menu span:last-child {
  margin-bottom: 0;
}
body.menu-open {
  overflow: hidden;
}

.banner-form {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}


.banner-form.contact-form {
  transform: rotateY(0deg);
}

.banner-form.contact-form.flipped {
  transform: rotateY(180deg);
}

.banner-form.family-form {
  transform: rotateY(-180deg);
}

.banner-form.family-form.flipped {
  transform: rotateY(0deg);
}

.form-container {
  perspective: 1000px;
  height: 700px;
  position: relative;
}
.form-content {
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

/* Ajusta el estilo existente del banner-form */
.banner-form {
  padding-right: 57px !important;
  background-color: #105a412b;
  padding: 30px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  width: 500px;
}

/* .formulario {
  margin-top: -59%;
  margin-left: 71%;
} */

.form-container {
  perspective: 1000px;
  width: 150%;
  max-width: 500px;
  margin: 0 auto;
}

.form-flipper {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.form-flipper.flipping {
  transform: rotateY(180deg);
}

.form-content {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  background-color: #105a412b;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.form-content.front {
  z-index: 2;
  transform: rotateY(0deg);
  margin-left: -17%;
}

.form-content.back {
  transform: rotateY(360deg);
  margin-left: -17%;
}
.custom-swal-button {
  background-color: #07593B !important;
  color: white !important;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #07593B;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: #063f2d;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

/* Mostrar la flecha cuando se ha scrolleado */
.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

